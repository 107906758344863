<template>
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top" style="background-color: #5E4843">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <i class="far fa-grin-hearts"></i>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{active: active === '/articles/everything_30s_dating'}"
               href="/articles/everything_30s_dating">아티클</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
               v-bind:class="{active: active && active.startsWith('/apps/')}">
              소개팅 어플
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="/apps/2024">연도별 인기 소개팅 어플</a></li>
              <li><a class="dropdown-item" href="/apps/for20s">20대 소개팅 어플</a></li>
              <li><a class="dropdown-item" href="/apps/for30s">30대 소개팅 어플</a></li>
              <li><a class="dropdown-item" href="/apps/for40s">40대 소개팅 어플</a></li>
              <li><a class="dropdown-item" href="/apps/companies">직장 인증 어플</a></li>
              <li><a class="dropdown-item" href="/apps/premium">프리미엄 소개팅 어플</a></li>
              <li><a class="dropdown-item" href="/apps/random_chatting">랜덤 채팅 어플</a></li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false"
               v-bind:class="{active: active && active.startsWith('/communities/')}">
              온라인 커뮤니티
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown2">
              <li><a class="dropdown-item" href="/communities/online">온라인 커뮤니티</a></li>
              <li><a class="dropdown-item" href="/communities/company">직장인 커뮤니티</a></li>
              <li><a class="dropdown-item" href="/communities/university">대학생 커뮤니티</a></li>
              <li><a class="dropdown-item" href="/communities/women">여초 커뮤니티</a></li>
            </ul>
          </li>

          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{active: active === '/apps/wedding_companies' || active === '/wedding_companies'}"
               href="/apps/wedding_companies">결혼 정보 회사</a>
          </li>

          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{active: active === '/real_estate_apps'}"
               href="/real_estate_apps">부동산 어플 순위</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    props: {},
    components: {},
    data() {
      return {
        active: null
      }
    },
    beforeMount() {
    },
    methods: {
    },
    mounted() {
      this.active = window.location.pathname;
    },
  }
</script>

<style lang="scss">
</style>