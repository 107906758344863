<template>
  <h1><select v-model="currentYear" @change="selectChanged($event)">
    <option v-for="option in yearOptions">
      {{ option }}
    </option>
  </select> 소개팅 어플 순위</h1>
  <section class="col-sm-12" v-for="(app, rank) in apps">
    <app-card :app="app" :rank="rank + 1" />
  </section>
</template>

<script>
import {defineComponent} from 'vue';
  import AppCard from './app_card.vue';

  export default defineComponent({
    props: {
      apps: {
        type: Array,
        required: true
      },
      year: {
        type: Number,
        required: true
      }
    },
    components: {AppCard},
    data() {
      return {
        currentYear: this.year,
        yearOptions: [2024,2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012],
      }
    },
    methods: {
      selectChanged(event) {
        window.location.href = `/apps/${this.currentYear}`
      }
    },
    mounted() {
    }
  });
</script>

<style lang="scss">
  @import '../stylesheets/common.scss';
  #app_index {
    h1 {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      margin: 25px;
    }
  }
</style>