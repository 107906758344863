<template>
  <h1>부동산 어플 정리</h1>

  <section>
    <app-card :app="호갱노노" rank="1"/>
    <div class="review">
      <p>
        호갱노노는 부동산 어플의 최고봉이라 할 수 있지.<br>
        입이 떡 벌어질 정도의 기능과 편리한 UI.<br>
        채광까지 시뮬레이션 해주는 기능을 보고는 정말 입을 다물지 못했어.<br>
        부동산 어플 중 단연 젤 잘 만든 어플 같아.<br>
        아파트나 오피스텔 가격 추이를 보고 싶으면 호갱노오 하나만 있으면 돼.<br>
        직방에 인수된 후로는 발전이 좀 더뎌진 것 같지만 말야.
      </p>
    </div>
  </section>

  <section>
    <app-card :app="네이버부동산" rank="2"/>
    <div class="review">
      <p>
        아무리 새로운 어플들이 나와도 구관이 명관인 법.<br>
        네이버 부동산은 절대 죽지 않아. 네이버 Never die.
      </p>
    </div>
  </section>

  <section>
    <app-card :app="직방" rank="3"/>
    <div class="review">
      <p>
        모바일 어플들이 태동하던 시절, 빠른 타이밍에 진입해서 시장을 싹 쓸어버렸지. <br>
        부동산도 중개앱으로 나오는구나 싶었던 사람들이 이제는 자연스럽게 집 구할 때 찾게하는 어플.<br>
        아파트보다는 빌라 같은 주택에 아무래도 많이 쓰이지.
      </p>
    </div>
  </section>

  <section>
    <app-card :app="아실" rank="4"/>
    <div class="review">
      <p>
        아파트 실거래가 보기 좋아. <br>
        특히 주택 가격 심리 지수를 무려 2008년부터 그래프로 보여주는데 나는 이게 너무 좋더라.<br>
        매수 심리는 거의 가격에 비례하거든. <br>
        이것만 트랙킹 해도 분위기를 어느 정도 알 수 있어.
      </p>
    </div>
  </section>

  <section>
    <app-card :app="피터팬" rank="5"/>
    <div class="review">
      <p>
        부동산 직거래 어플이야. 세입자가 이사갈 때 자기 방 올리라고 만든 곳.<br>
        복비를 아끼려는 사람들은 이 서비스를 꼭 써봐.<br>
        요즘엔 당근마켓에도 부동산 직거래가 서비스가 나왔더라구.(아직 서울 한정)
      </p>
    </div>
  </section>

  <section>
    <app-card :app="다방" rank="6"/>
    <div class="review">
      <p>
        직방의 뒤를 잇고 있는 부동산 중개앱이지만 어플 자체는 직방보다 잘 만든 것 같아.<br>
        UI도 직방보다 낫고 기능도 좋고.<br>
        근데 영 직방을 이기지 못한단 말이지.<br>
        기술적인 부분 말고 허위매물 관리에 좀 더 신경쓰면 어떨까?
      </p>
    </div>
  </section>

  <section>
    <app-card :app="네모" rank="7"/>
    <div class="review">
      <p>
        부동산엔 주택이나 아파트만 있는게 아니야.<br>
        자영업자들이나 소규모 회사 대표들은 사무실 구하는게 참 일이거든.<br>
        네모는 이런 사람들을 위한 서비스야.<br>
        상가사 사무실 필요하다면 다른 어플들보다 네모를 찾아봐.
      </p>
    </div>
  </section>

  <section>
    <app-card :app="집토스" rank="8"/>
    <div class="review">
      <p>
        세입자에게는 복비를 안받는다.<br>
        집주인이게만 복비를 받는다.<br>
        기득권 부동산들로부터 공격을 많이 받고 있지만 나는 응원해주고 싶어!<br>
        나도 세입자라구...
      </p>
    </div>
  </section>

  <section>
    <app-card :app="밸류맵" rank="9"/>
    <div class="review">
      <p>
        건물 가격을 보여주는 어플이야.<br>
        전국의 모든 건물과 토지를 보여주기 때문에 건물 가격을 보고 싶다면 이 어플을 써봐.<br>
        아파트는 호갱노노를 보면 되지만 다가구 주택이나 다세대 주택은 밸류맵만한 게 없어.<br>
        건물 실거래가는 물론 현재 나와있는 매물까지도 볼 수 있다구.
      </p>
    </div>
  </section>

  <section>
    <app-card :app="디스코" rank="10"/>
    <div class="review">
      <p>
        밸류맵과 비슷한 어플이야. 건물과 토지 실거래가를 보여주는 서비스.<br>
        등기부등본을 쉽게 떼어볼 수 있는 서비스가 참 좋아.
      </p>
    </div>
  </section>

  <section>
    <app-card :app="스페이스클라우드" rank="11"/>
    <div class="review">
      <p>
        공간대여라는 말 들어봤어?<br>
        파티룸 같은 공간을 1시간 단위로 빌려서 놀 수 있는 곳이야.<br>
        코로나 이후 상가와 사무실들이 타격을 많이 입었어.<br>
        그런데 의외로 잘하고 있는 곳들이 있거든. 바로 파티룸 공간 사업.<br>
        거리두기로 사람들이 저녁 늦게까지 놀 수가 없으니 이런 공간들을 빌려서 놀기 시작한거야.<br>
        스페이스클라우드는 그런 파티룸들을 중개해주는 서비스지.
      </p>
    </div>
  </section>
</template>

<script>
import GlobalNavHeader from "./global_nav_header.vue";
import AppCard from "./app_card.vue"

import hogangnono from '@/images/real_estate_apps/호갱노노.png'
import nemo from '@/images/real_estate_apps/네모.png'
import naver_real_estate from '@/images/real_estate_apps/네이버부동산.png'
import asil from '@/images/real_estate_apps/아실.png'
import dabang from '@/images/real_estate_apps/다방.png'
import disco from '@/images/real_estate_apps/디스코.png'
import buplanet from '@/images/real_estate_apps/부동산플래닛.png'
import spacecloud from '@/images/real_estate_apps/스페이스클라우드.png'
import zigbang from '@/images/real_estate_apps/직방.png'
import ziptoss from '@/images/real_estate_apps/집토스.png'
import peterpan from '@/images/real_estate_apps/피터팬.png'
import valuemap from '@/images/real_estate_apps/valuemap.png'

export default {
    props: {

    },
    components: {GlobalNavHeader, AppCard},
    data() {
        return {
            '호갱노노': {
                title: '호갱노노',
                hashTags: ['아파트 실거래가', '오피스텔'],
                star: 4.4,
                company: '호갱노노',
                logo: hogangnono,
                serviceUrl: 'https://play.google.com/store/apps/details?id=com.hogangnono.hogangnono&hl=ko&gl=US',
                companyUrl: 'https://career.zigbang.com/info',
            },
            '네모': {
                title: '네모',
                hashTags: ['사무실', '상업용 부동산', '점포'],
                star: 3.9,
                company: '슈가힐',
                logo: nemo,
                serviceUrl: 'https://play.google.com/store/apps/details?id=kr.co.sugarhill.nemoapp&hl=ko&gl=US',
                companyUrl: 'https://www.sugarhill.co.kr/',
            },
            '네이버부동산': {
                title: '네이버 부동산',
                hashTags: ['대중'],
                star: 4.0,
                company: '네이버',
                logo: naver_real_estate,
                serviceUrl: 'https://play.google.com/store/apps/details?id=com.nhn.land.android&hl=ko&gl=US',
                companyUrl: 'https://navercorp.com',
            },
            '아실': {
                title: '아실',
                hashTags: ['아파트 실거래가', '부동산 심리지수', '거래량', '갭투자', '매수심리'],
                star: 4.0,
                company: '아실',
                logo: asil,
                serviceUrl: 'https://play.google.com/store/apps/details?id=kr.co.koreachart.city&hl=ko&gl=US',
                companyUrl: 'https://asil.kr/asil/index.jsp',
            },
            '다방': {
                title: '다방',
                hashTags: ['직방을 뒤따르는 중개앱 2인자'],
                star: 4.1,
                company: 'station3',
                logo: dabang,
                serviceUrl: 'https://play.google.com/store/apps/details?id=kr.co.station3.dabang&hl=ko&gl=US',
                companyUrl: 'https://www.station3.co.kr/',
            },
            '디스코': {
                title: '디스코',
                hashTags: ['주택 매매', '실거래가', '주택 거래'],
                star: 4.0,
                company: '디스코',
                logo: disco,
                serviceUrl: 'https://play.google.com/store/apps/details?id=me.aftergrowth.disco&hl=ko&gl=US',
                companyUrl: 'https://disco.re',
            },
            '부동산플래닛': {
                title: '부동산플래닛',
                hashTags: ['빌딩 실거래가'],
                star: 3.7,
                company: '부동산플래닛',
                logo: buplanet,
                serviceUrl: 'https://play.google.com/store/apps/details?id=com.ytp.buchamp&hl=ko&gl=US',
                companyUrl: 'https://www.bdsplanet.com/map/biz_map.ytp',
            },
            '스페이스클라우드': {
                title: '스페이스클라우드',
                hashTags: ['파티룸', '공간 대여'],
                star: 4.2,
                company: '스페이스클라우드',
                logo: spacecloud,
                serviceUrl: 'https://play.google.com/store/apps/details?id=com.hustay.swing.p3cfded673ced4983b0db9399638730cb&hl=ko&gl=US',
                companyUrl: 'https://www.spacecloud.kr/',
            },
            '직방': {
                title: '직방',
                hashTags: ['안성우', '부동산 중개 어플 1인자'],
                star: 3.8,
                company: '직방',
                logo: zigbang,
                serviceUrl: 'https://play.google.com/store/apps/details?id=com.chbreeze.jikbang4a&hl=ko&gl=US',
                companyUrl: 'https://career.zigbang.com/info',
            },
            '집토스': {
                title: '집토스',
                hashTags: ['복비 무료'],
                star: 4.1,
                company: '집토스',
                logo: ziptoss,
                serviceUrl: 'https://play.google.com/store/apps/details?id=com.ziptoss.v2&hl=ko&gl=US',
                companyUrl: 'https://ziptoss.com/',
            },
            '피터팬': {
                title: '피터팬의 좋은방 구하기',
                hashTags: ['직거래', '두꺼비세상', '헌집줄게 새집다오', '원룸', '투룸'],
                star: 3.4,
                company: '피터팬',
                logo: peterpan,
                serviceUrl: 'https://play.google.com/store/apps/details?id=com.appz.dukkuba',
                companyUrl: 'http://www.duse.co.kr/views/home.html',
            },
            '밸류맵': {
                title: '밸류맵 - 건물 토지 실거래가',
                hashTags: ['밸류맵', '건물', '토지', '실거래가', '건물 매각', '건물 임장', '건물주'],
                star: 3.9,
                company: '밸류업시스템즈',
                logo: valuemap,
                serviceUrl: 'https://play.google.com/store/apps/details?id=com.valueupsystems.valuemap&hl=ko&gl=US',
                companyUrl: 'https://www.valueupmap.com/',
            },
        }
    },
    methods: {},
    mounted() {
    }
}
</script>

<style lang="scss">
@import '../stylesheets/common.scss';
#real_estate_apps {
  font-size: 16px;

  .intro {
    padding: 15px 15px 0 15px;
  }

  address {
    padding: 0 15px;
    margin-bottom: 50px;
  }

  h1 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin: 25px;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  h5 {
    font-size: 15px;
    font-weight: bold;
  }

  .review {
    margin-top: 0;
    padding: 0 30px 30px 30px;

    p {
      font-size: 15px;
    }

    ol, ul {
      font-size: 14px;

      li {
        margin-top: 10px;
      }
    }
  }
}
</style>